/**
 * This store contains methods to find and load posts matching the search.
 */

import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import router from "@/router";
import { searchModule, SearchState } from "./Search";
import { postsModule, PostsState } from "./Posts";
import PostService from "@/services/PostService";
import Post from "@/models/post";
import radii from "@/assets/resources/radii";

Vue.use(Vuex);

export interface RootState {
  searchModule: SearchState;
  postsModule: PostsState;
  radiusExtended: boolean;
  radiusExtendedFrom?: string;
}

const store: StoreOptions<RootState> = {
  modules: {
    searchModule,
    postsModule,
  },
  state: {
    radiusExtended: false,
    radiusExtendedFrom: undefined,
  } as RootState,
  getters: {},
  mutations: {},
  actions: {
    clearSearchParams({ commit }): void {
      commit("searchModule/clearSearchParams");
      commit("postsModule/clearPostParams");
    },
    async findPosts({ commit, state }): Promise<Post[]> {
      const location = state.searchModule.selectedLocation;
      const searchValues = state.searchModule.searchValues;
      const radius = state.searchModule.selectedRadius;

      const from = state.postsModule.resultsFrom;
      const size = state.postsModule.resultSetSize;
      const international = state.searchModule.isInternational;

      const posts = await PostService.findPosts({
        searchValues,
        location,
        radius,
        from,
        size,
        international,
      });

      commit("setTotalResultSize", posts.meta.total);
      commit("setPosts", posts.data);
      return posts.data;
    },
    /**
     * Sets state values from values of the current route
     */
    async hydrateStateFromRoute({ commit, dispatch }): Promise<any> {
      const queryParams = router.currentRoute.query as any;
      const params = router.currentRoute.params;

      // Clear previous search parameters. The URI is our single source of truth!
      await dispatch("clearSearchParams");
      const promises = new Array<Promise<any>>();

      // get query string
      if ("q" in queryParams && queryParams.q)
        promises.push(
          dispatch("searchModule/addSearchValues", queryParams.q.split(","))
        );
      // get area value
      if ("area" in queryParams && queryParams.area)
        commit(
          "searchModule/setInternational",
          queryParams.area.toLowerCase() === "international"
        );
      // get location value
      if ("location" in queryParams && queryParams.location)
        commit("searchModule/setSelectedLocation", queryParams.location);
      // get radius value
      if ("radius" in queryParams && queryParams.radius)
        commit("searchModule/setSelectedRadius", queryParams.radius);
      // get page value
      if ("page" in queryParams && queryParams.page)
        promises.push(
          dispatch(
            "postsModule/setSelectedPage",
            parseInt(queryParams.page, 10)
          )
        );
      // get selected post id value
      if ("id" in params && params.id)
        promises.push(dispatch("postsModule/setSelectedPostId", params.id));

      return await Promise.all(promises);
    },

    /**
     * Updates url parameter with current values from the store
     */
    updateURIFromState({ state, getters }): void {
      const query = {
        ...router.currentRoute.query,
        q: state.searchModule.searchValues.join(","),
        area: state.searchModule.isInternational ? "international" : "national",
        location: getters["searchModule/getLocationText"],
        radius: state.searchModule.selectedRadius,
        page:
          state.postsModule.selectedPage > 1
            ? state.postsModule.selectedPage.toString()
            : undefined,
      };
      let path = "/posts";
      // is there a post currently open? => reflect it in the route
      if (state.postsModule.selectedPostId)
        path += "/" + state.postsModule.selectedPostId;

      // only change route if query parameter change from current query parameter
      if (
        JSON.stringify(query) !== JSON.stringify(router.currentRoute.query) ||
        router.currentRoute.path !== path
      )
        router.push({
          path,
          query,
        });
    },

    /**
     *  Load a post from DataService by given id.
     */
    async loadPost(context, id: string): Promise<Post | undefined> {
      return PostService.findById(id);
    },

    /**
     *  Load posts from DataService by setted parameter.
     */
    async loadPosts({ state, dispatch, commit }): Promise<Post[]> {
      // clear extended properties
      if (!state.radiusExtended) state.radiusExtendedFrom = undefined;
      else state.radiusExtended = false;

      // clear old posts
      state.postsModule.posts = [];

      const result = await PostService.findPosts({
        searchValues: state.searchModule.searchValues,
        location: state.searchModule.selectedLocation,
        radius: state.searchModule.selectedRadius,
        from: state.postsModule.resultsFrom,
        size: state.postsModule.resultSetSize,
        international: state.searchModule.isInternational,
      });

      state.postsModule.totalResultSize = result.meta.total;
      await dispatch("postsModule/setPosts", result.data);

      const posts = result.data;

      // there is a full list of posts
      if (posts.length) {
        // set Open post if list contains only one post.
        if (posts.length === 1)
          dispatch("postsModule/setSelectedPostId", posts[0].id);

        return posts;
      }
      // if there are no posts in the list and // if a location and a radius is set
      else if (
        state.searchModule.selectedLocation &&
        state.searchModule.selectedRadius
      ) {
        const radiusValueBeforeExtend = state.searchModule.selectedRadius;
        // Wenn wir mit einem Radius um einen Ort suchen, den Radius vergroeßern und nochmal probieren!

        // find radius index of radii
        const currentRadiusIndex = radii.findIndex(
          (r) => r.value === radiusValueBeforeExtend
        );
        // find next bigger radii
        const nextBiggerRadiusValue =
          radii[(currentRadiusIndex + 1) % radii.length].value;

        // We want to notice whether the radius changed to inform the user
        // but only if we did not already do so in order to not overwrite the value.
        const extendFrom = state.radiusExtendedFrom || radiusValueBeforeExtend;

        // update radius
        commit("searchModule/setSelectedRadius", nextBiggerRadiusValue);
        // load posts again
        const newPosts = (await this.dispatch("loadPosts")) as Post[];

        // set extended properties after new posts are loaded
        state.radiusExtended = true;
        state.radiusExtendedFrom = extendFrom;

        return newPosts;
      }
      return [];
    },
  },
};

export default new Vuex.Store<RootState>(store);
