












import Vue from "vue";
// import Toolbar from "@/components/layout/Toolbar.vue";
import Footer from "@/components/layout/Footer.vue";
import ScrollTopButton from "@/components/layout/ScrollTopButton.vue";

export default Vue.extend({
  name: "App",
  components: {
    Footer,
    ScrollTopButton,
    // Toolbar,
  },
});
