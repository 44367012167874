


















import Vue from "vue";

export default Vue.extend({
  name: "ScrollTopButton",
  data: function () {
    return {
      fab: false,
    };
  },
  methods: {
    /**
     * Allows fab to be shown if the user has scrolled down at least 20px.
     */
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
});
